import React, { useState } from "react";
import ServerTable from "./ServerTable/ServerTable";

function Servers() {
  return (
    <>
      <ServerTable />
    </>
  );
}

export default Servers;
